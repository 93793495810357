#hellopreloader_preload {
  display: block;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;
  background: #050404;
}

#hellopreloader_loadingWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  width: 100%;
}

#hellopreloader_percentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

#hellopreloader_percent,
#hellopreloader_percentText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "MoscowSans", sans-serif;
  color: #c1c1c4;
  margin: 0;
}

#hellopreloader_percent {
  width: 100%;
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
}

#hellopreloader_percentText {
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
}

#hellopreloader_barWrapper,
#hellopreloader_bar {
  height: 6px;
  border-radius: 20px;
}

#hellopreloader_barWrapper {
  margin: 20px 0;
  width: 100%;
  background-color: #414049;
}

#hellopreloader_bar {
  background-color: #c1c1c4;
}
