html.a-fullscreen {
  position: static !important;
}

html.a-fullscreen.position {
  position: fixed !important;
}

html.a-fullscreen body {
  overflow: auto;
}

.aframeOne, .aframeTwo,
#aframeHTMLOne, #aframeHTMLTwo {
  height: 100%;
  width: 100%;
}

a-scene {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all .5s;
  position: relative;
}

#scenePier {
  height: 100%;
  width: 100%;
}

#aframe_rollupPier,
#aframe_rollupSinich {
  display: none;
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 24px 30px;
  background: rgba(26, 27, 29, 0.70);
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
}

.aframeOne svg,
.aframeTwo svg {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-content {
  z-index: 1;
}

.a-enter-vr-button {
  display: none !important;
}

@media (max-width: 1200px) {
  .a-dialog {
    border-radius: 20px;
    background: #A7A5B4;
    padding: 20px;
    max-height: 250px;
    height: 100%;
  }

  .a-dialog-allow-button,
  .a-dialog-deny-button,
  .a-dialog-ok-button {
    border-radius: 10px;
  }

  .a-dialog-allow-button,
  .a-dialog-deny-button,
  .a-dialog-ok-button,
  .a-dialog-text,
  .a-https-text{
    font-family: "MoscowSans", sans-serif;
    color: #ffffff;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  .a-dialog-text {
    margin-bottom: 20px;
  }

  .a-dialog-allow-button {
    background-color: #59C598;
  }

  .a-dialog-deny-button {
    background-color: #BBB9C8;
  }

  .a-dialog-ok-button {
    background-color: #BBB9C8;
  }
}