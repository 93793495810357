@charset "UTF-8";
@import './fonts.css';
#hellopreloader_preload {
  display: block;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;
  background: #050404;
}

#hellopreloader_loadingWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  width: 100%;
}

#hellopreloader_percentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

#hellopreloader_percent,
#hellopreloader_percentText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "MoscowSans", sans-serif;
  color: #c1c1c4;
  margin: 0;
}

#hellopreloader_percent {
  width: 100%;
  font-size: 60px;
  font-weight: 800;
  line-height: 60px;
}

#hellopreloader_percentText {
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
}

#hellopreloader_barWrapper,
#hellopreloader_bar {
  height: 6px;
  border-radius: 20px;
}

#hellopreloader_barWrapper {
  margin: 20px 0;
  width: 100%;
  background-color: #414049;
}

#hellopreloader_bar {
  background-color: #c1c1c4;
}

body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #FFFFFF;
  font-family: "MoscowSans", sans-serif;
  background-color: #9794A6;
}

body::-webkit-scrollbar {
  background-color: #9794A6;
  width: 0.4em; /* Ширина полосы прокрутки (по желанию) */
}

body::-webkit-scrollbar-thumb {
  background-color: #7A768D; /* Цвет полосы прокрутки (по желанию) */
}

h1 {
  margin: 0;
  width: 100%;
  font-size: 5rem;
  line-height: 5rem;
  font-weight: 700;
}

h2 {
  margin: 0;
  width: 100%;
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-weight: 700;
}

h3 {
  margin: 0;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
}

p, ol, ul {
  width: 100%;
  font-size: 1.125em;
  line-height: 1.3rem;
}

.d-block {
  display: block;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-right {
  text-align: right;
}

.align-self-fs {
  align-self: flex-start;
}

.row-gap-10 {
  row-gap: 10px;
}

.col-gap-10 {
  column-gap: 10px;
}

.bg-grey-grad {
  background: linear-gradient(287.08deg, #A19FAE 11.76%, #ACAABA 90.75%);
}

.bg-grey-grad05 {
  background: linear-gradient(287.08deg, rgba(161, 159, 174, 0.5) 11.76%, rgba(172, 170, 186, 0.5) 90.75%);
}

.fg-1 {
  flex-grow: 1;
}

.fs-15 {
  font-size: 1.5rem;
  line-height: 1.95rem;
}

.fs-1125 {
  font-size: 1.125rem;
  line-height: 1.45rem;
}

.bold {
  font-weight: 600;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.text-grey {
  color: #9794A6;
}

.btn-grey {
  background-color: #9794A6;
}

.bg-grey {
  background-color: #9794A6;
}

.text-secondary-grey {
  color: #D1D1D1;
}

.btn-secondary-grey {
  background-color: #D1D1D1;
}

.bg-secondary-grey {
  background-color: #D1D1D1;
}

.text-light-grey {
  color: #A19FAE;
}

.btn-light-grey {
  background-color: #A19FAE;
}

.bg-light-grey {
  background-color: #A19FAE;
}

.text-dark-grey {
  color: #7A768D;
}

.btn-dark-grey {
  background-color: #7A768D;
}

.bg-dark-grey {
  background-color: #7A768D;
}

.text-white {
  color: #FFFFFF;
}

.btn-white {
  background-color: #FFFFFF;
}

.bg-white {
  background-color: #FFFFFF;
}

.text-black {
  color: #000000;
}

.btn-black {
  background-color: #000000;
}

.bg-black {
  background-color: #000000;
}

.text-asphalt {
  color: #403F3C;
}

.btn-asphalt {
  background-color: #403F3C;
}

.bg-asphalt {
  background-color: #403F3C;
}

.col-1 {
  grid-column: span 1;
}

.col-2 {
  grid-column: span 2;
}

.col-3 {
  grid-column: span 3;
}

.col-4 {
  grid-column: span 4;
}

.col-5 {
  grid-column: span 5;
}

.col-6 {
  grid-column: span 6;
}

.col-7 {
  grid-column: span 7;
}

.col-8 {
  grid-column: span 8;
}

.col-9 {
  grid-column: span 9;
}

.col-10 {
  grid-column: span 10;
}

.col-11 {
  grid-column: span 11;
}

.col-12 {
  grid-column: span 12;
}

.row-1 {
  grid-row: span 1;
}

.row-2 {
  grid-row: span 2;
}

.row-3 {
  grid-row: span 3;
}

.row-4 {
  grid-row: span 4;
}

.row-5 {
  grid-row: span 5;
}

.row-6 {
  grid-row: span 6;
}

.row-7 {
  grid-row: span 7;
}

.row-8 {
  grid-row: span 8;
}

.row-9 {
  grid-row: span 9;
}

.row-10 {
  grid-row: span 10;
}

.row-11 {
  grid-row: span 11;
}

.row-12 {
  grid-row: span 12;
}

@media (max-width: 1024px) {
  p, ol li, ul li {
    font-size: 1rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
}
@media (max-width: 900px) {
  h1 {
    font-size: 3.3rem;
    line-height: 3.65rem;
  }
  p, ol li, ul li {
    font-size: 1rem;
    line-height: 1.125rem;
  }
  .fs-125-tab {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .fs-1-tab {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .fs-1125-tab {
    font-size: 1.125rem;
    line-height: 1.45rem;
  }
  .fs-0875-tab {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}
@media (max-width: 440px) {
  h1 {
    font-size: 2.3rem;
    line-height: 2.4rem;
  }
  .p-30-mob {
    padding: 30px;
  }
}
.circle {
  display: block;
  height: 6.2%;
  width: 4%;
  background-color: transparent;
  border-radius: 50%;
  z-index: 200;
}

.circle-hover-img {
  opacity: 0;
  transition: opacity 0.7s;
}

.modal-body img:not(:first-child) {
  width: 191px;
  border-radius: 20px;
}

.filter-white {
  filter: invert(99%) sepia(6%) saturate(0%) hue-rotate(197deg) brightness(117%) contrast(100%);
}

.sepia-grey-grad:hover {
  background: url("/public/images/sepia.png"), linear-gradient(287.08deg, rgb(161, 159, 174) 11.76%, rgb(172, 170, 186) 90.75%);
}

.sepia-black:hover {
  background: url("/public/images/sepia.png"), #000000;
}

.sepia-grey:hover {
  background: url("/public/images/sepia.png"), rgba(161, 159, 174, 0.5);
}

.opacity-1 {
  opacity: 1;
}

.height-1 {
  height: 100%;
}

.width-1 {
  width: 100%;
}

.max-width-400 {
  max-width: 400px;
}

.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

.flex-11auto {
  flex: 1 1 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.container {
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1400px;
  margin: auto;
  position: relative;
}

.btn {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 10px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
}

.grid-12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.grid-12-rows {
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
}

.card {
  position: relative;
  overflow: clip;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 40px;
  overflow: hidden;
  transition: 0.7s;
}
.card:hover {
  filter: brightness(110%);
}
.card:hover .card-img {
  transform: scale(1.05);
}

.card-sm {
  height: 400px;
}

.card-large {
  height: 100%;
}

.card-faq {
  height: 522px;
}

.card-disabled {
  opacity: 0.7;
}
.card-disabled:hover {
  filter: none;
}
.card-disabled:hover .card-img {
  transform: none;
}
.card-disabled > * {
  cursor: default;
}

.card-img {
  transition: transform 0.5s ease;
}

.card-bottom-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.card-center-img {
  position: absolute;
  right: 0;
  bottom: 64px;
  max-width: 260px;
}

.card-content {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
  position: absolute;
  width: 414px;
  bottom: 30px;
  right: 30px;
  border-radius: 40px;
  backdrop-filter: blur(8px);
}

.card-flex-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.card-flex-wrapper .card {
  flex: 1 1 0;
}

.btn-link {
  background-color: #000000;
  width: 190px;
  opacity: 1;
  z-index: 10;
  transition: 0.7s;
}
.btn-link:hover {
  opacity: 0.7;
}

.rotation-icon {
  transition: transform 0.7s ease;
}

.btn-link-footer {
  background-color: #A19FAE;
  width: 320px;
}
.btn-link-footer:hover {
  opacity: 1;
}

.route-number {
  align-self: flex-start;
}

.logos-flex-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer-links-wrapper {
  margin-right: auto;
  margin-left: auto;
}

.footer-links-wrapper-tab {
  display: none;
}

.footer-list {
  list-style: none;
  padding-inline-start: 0;
}

.footer-list-item {
  width: fit-content;
  margin-bottom: 5px;
}
.footer-list-item:hover .footer-link {
  color: #FFFFFF;
}

.footer-link {
  color: #D1D1D1;
  display: block;
  text-decoration: none;
}

.footer-link.mobile_number {
  text-decoration: none !important;
}

.footer-contacts {
  text-align: right;
}

.bg-pier {
  background-image: url("/public/images/pier.png");
  background-position: center;
  background-size: cover;
}

.img-responsive {
  display: block;
  width: 100%;
  max-height: 413px;
  height: auto;
}

.img-responsive60 {
  display: block;
  width: 60%;
  height: auto;
}

.card-body-disabled {
  opacity: 0;
  height: 0;
  transition: opacity 0.7s;
}
.card-body-disabled > * {
  display: none;
}

.card-body-active {
  opacity: 1;
  height: 100%;
  width: 100%;
  transition: opacity 0.7s;
}

.d-none {
  display: none;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.modal {
  position: fixed;
  width: 100vw;
  height: calc(100vh + 50px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.modal-bg {
  position: absolute;
  background-color: rgba(64, 63, 60, 0.9);
  width: 100%;
  height: 100%;
}

.modal-container {
  position: relative;
  width: 60%;
}

.modal-close {
  padding: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  outline: none;
  appearance: none;
  color: red;
  background: none;
  border: 0px;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .mt-20-descsemi {
    margin-top: 20px;
  }
  .mb-20-descsemi {
    margin-bottom: 20px;
  }
  .mt-10-descsemi {
    margin-top: 10px;
  }
  .mb-10-descsemi {
    margin-bottom: 10px;
  }
  .col-6-descsemi {
    grid-column: span 6;
  }
}
@media (max-width: 1024px) {
  .mt-20-htab {
    margin-top: 20px;
  }
  .mb-20-htab {
    margin-bottom: 20px;
  }
  .card-center-img {
    max-width: 180px;
    bottom: 120px;
  }
  .card-large {
    justify-content: flex-end;
  }
  .card-faq {
    height: 530px;
  }
  .card-content {
    position: relative;
    width: auto;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 900px) {
  .card-content {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 414px;
  }
  .d-none-tab {
    display: none;
  }
  .mb-50-tab {
    margin-bottom: 50px;
  }
  .d-flex-tab {
    display: flex;
    flex-direction: row;
  }
  .d-flex-col-tab {
    display: flex;
    flex-direction: column;
  }
  .gap10-tab {
    gap: 10px;
  }
  .img-responsive75-tab {
    width: 75%;
    height: auto;
  }
  .btn {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .modal-close {
    padding: 0;
  }
  .card-large {
    height: 500px;
  }
  .card-flex-wrapper {
    flex-direction: column;
  }
  .card-flex-wrapper .card-faq {
    flex: 1 1 290px;
  }
  .card-center-img {
    max-width: 160px;
    bottom: 30px;
  }
  .footer-links-wrapper > * {
    display: none;
  }
  .footer-links-wrapper-tab {
    display: flex;
    justify-content: space-between;
  }
  .footer-link {
    color: #FFFFFF;
  }
  .footer-links-wrapper-tab .footer-link {
    border-bottom: 1px solid #FFFFFF;
  }
  .btn-link-footer {
    width: 100%;
  }
  .modal-container {
    width: 75%;
  }
}
@media (max-width: 440px) {
  .mb-20-mob {
    margin-bottom: 20px;
  }
  .mb-40-mob {
    margin-bottom: 40px;
  }
  .mt-50-mob {
    margin-top: 50px;
  }
  .pt-30-mob {
    padding-top: 30px;
  }
  .px-30-mob {
    padding-right: 30px;
    padding-left: 30px;
  }
  .d-flex-col-mob {
    display: flex;
    flex-direction: column;
  }
  .d-flex-col-mob {
    display: flex;
    flex-direction: column;
  }
  .gap10-mob {
    gap: 10px;
  }
  .card {
    padding: 20px;
  }
  .card-large {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .card-content {
    position: relative;
    width: auto;
    right: 0;
    bottom: 0;
  }
  .card-center-img {
    bottom: 75px;
  }
  .logos-flex-wrapper {
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .footer-contacts {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
@media (max-width: 800px) {
  .d-none-mob {
    display: none;
  }
  .d-block-mob {
    display: block;
  }
}
@media (max-width: 480px) {
  .card-flex-wrapper .card-faq {
    flex: 1 1 350px;
  }
}
@media (max-width: 390px) {
  .card-flex-wrapper .card-faq {
    flex: 1 1 380px;
  }
}
.container_video {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 2;
}

.video_progress_container {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_progress_container .progress-container {
  position: relative;
  max-width: 374px;
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background: #9794A6;
  transition: background-color 0.4s; /* Добавляем переход для background-color */
  margin: 0 8px;
}

.progress-bar {
  height: 100%;
  width: 0;
  border-radius: 2px;
  background: #FFF;
  transition: width 0.4s;
}

.progress_icon,
.progress_icon-first {
  position: relative;
  padding: 30px;
  z-index: 1;
  border-radius: 10px;
  background: transparent;
  border: 2px solid #9794A6;
}

.progress_icon-first {
  margin-left: 20px;
}

.progress_icon .progress_content,
.progress_icon-first .progress_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "MoscowSans", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #9794A6;
  transition: all 0.5s ease;
}

.progress_icon-first,
.progress_icon.filled {
  background-color: #FFF;
  border-color: #FFF;
  opacity: 1;
}

.progress_icon-first .progress_content,
.progress_icon.filled .progress_content {
  color: #050404;
}

.progress_icon:last-child {
  margin-right: 20px;
}

.video_content {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 16;
  transition: opacity 0.5s;
  background: url("/public/images/firstCadr.jpg") no-repeat center/cover;
}

.video_content .video-background,
.video_content .video-background-wrapper {
  display: none;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.video_content .video-background-wrapper {
  height: 100%;
}

.video_content .mouse_prev .mouse_prev-wrapper_mobile,
.video_content .mouse_prev .mouse_prev-wrapper_desctop {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
  color: #9794A6;
  font-family: "MoscowSans", sans-serif;
  font-weight: 500;
}

.video_content .mouse_prev .mouse_prev-wrapper_desctop {
  display: none;
  font-size: 22px;
}

.video_content .mouse_prev .mouse_prev-wrapper_mobile {
  display: none;
  font-size: 16px;
}

.video_content .mouse_prev .mouse_prev-wrapper_desctop .mouse_prev_arrow {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 42px;
  opacity: 0.5;
}

.video_content .mouse_prev .mouse_prev-wrapper_desctop .mouse_prev_arrow img {
  position: absolute;
  height: 32px;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  animation: mouse_arrow 1.1s linear infinite;
}

@keyframes mouse_arrow {
  0% {
    top: 5px;
  }
  50% {
    top: 10px;
  }
  100% {
    top: 5px;
  }
}
.autoflash {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.flash {
  content: "";
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 3em;
  display: block;
  position: absolute;
  top: 0;
  left: -4.5em;
  -webkit-transform: skewX(-45deg) translateX(0);
  transform: skewX(-45deg) translateX(0);
  -webkit-transition: none;
  transition: all 0.4s;
}

.lighting {
  -webkit-animation: moving 5s ease-in-out infinite;
  -moz-animation: moving 5s ease-in-out infinite;
  -ms-animation: moving 5s ease-in-out infinite;
  -o-animation: moving 5s ease-in-out infinite;
  animation: moving 5s ease-in-out infinite;
}

@keyframes moving {
  30% {
    webkit-transform: skewX(-45deg) translateX(33.5em);
    transform: skewX(-45deg) translateX(33.5em);
  }
  100% {
    webkit-transform: skewX(-45deg) translateX(33.5em);
    transform: skewX(-45deg) translateX(33.5em);
  }
}
.video_content .leave_button {
  display: none;
  right: 20px;
}

.video_content .reload_button {
  display: none;
  left: 20px;
}

.video_content .leave_button,
.video_content .reload_button {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 82px;
  cursor: pointer;
  z-index: 1;
}

.video_content .leave_button button,
.video_content .reload_button button {
  font-family: "MoscowSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  margin: 0;
  padding: 0;
  color: white;
  background: transparent;
  cursor: pointer;
}

.video_content .reload_button img {
  transform: rotate(180deg);
}

.video_content .learn_button {
  display: none;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 28px;
  width: max-content;
  cursor: pointer;
  z-index: 1;
  border-radius: 20px;
  background: linear-gradient(292deg, rgba(161, 159, 174, 0.5) 0%, rgba(172, 170, 186, 0.5) 100%);
  backdrop-filter: blur(10px);
}

.video_content .learn_button button {
  text-transform: uppercase;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "MoscowSans", sans-serif;
  font-size: 22px;
  line-height: 20px;
  font-weight: 300;
  color: #ffffff;
  background: transparent;
}

.video_content .info_block,
.video_content .mouse_prev {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.video_content .info_block .card {
  position: absolute;
  max-width: 474px;
  width: max-content;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  padding: 32px;
  border-radius: 20px;
  background: linear-gradient(292deg, rgba(161, 159, 174, 0.5) 0%, rgba(172, 170, 186, 0.5) 100%);
  backdrop-filter: blur(10px);
  bottom: 260px;
  left: 50%;
  transform: translateX(-50%);
}

.video_content .info_block .card .card_marker {
  position: relative;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.4);
}

.video_content .info_block .card .card_marker .card_marker-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video_content .info_block .card .card_descr .card_descr-text,
.video_content .info_block .card .card_descr .card_descr-title {
  font-family: "MoscowSans", sans-serif;
  margin: 0;
}

.video_content .info_block .card .card_descr .card_descr-title {
  font-size: 32px;
  line-height: 35px;
  font-weight: 600;
  color: #ffffff;
}

.video_content .info_block .card .card_descr .card_descr-text {
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  color: #CBCBCD;
  margin-bottom: 10px;
}

.video_content .info_block .card.animateUp {
  animation: CardUp 0.5s linear;
}

.video_content .info_block .card.animateDown {
  animation: CardDown 0.5s linear;
}

/*Поднимает кнопку снизу вверх на px*/
@keyframes CardUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 260px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
/*Появление сверху вниз*/
@keyframes CardDown {
  0% {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 260px);
  }
}
.section-video {
  pointer-events: none;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  height: 100vh;
  object-fit: cover;
}

.section-video.section-video_active {
  display: block !important;
  z-index: 100 !important;
}

@media (min-height: 850px) and (max-width: 680px) {
  .video_content .video-background-wrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 250px;
    z-index: 1;
    background: linear-gradient(180deg, #000 80%, rgba(34, 34, 34, 0) 100%);
  }
  .video_content .video-background,
  .video_content .video-background-wrapper {
    position: fixed;
    bottom: 0;
    transform: scaleX(1.04);
  }
}
@media (max-width: 1200px) {
  .video_content .bg-video,
  .video_progress_container,
  .video_content .mouse_prev .mouse_prev-wrapper_desctop,
  .video_content .mouse_prev .mouse_prev-wrapper_mobile,
  .video_content .leave_button, .video_content .reload_button {
    display: none;
  }
  .video_content .video-background,
  .video_content .video-background-wrapper {
    display: block;
  }
  .video_content {
    background: black;
  }
  .video_content .learn_button {
    display: flex;
  }
  .video_content .info_block .card {
    bottom: 190px;
  }
  /*Поднимает кнопку снизу вверх на px*/
  @keyframes CardUp {
    0% {
      opacity: 0;
      transform: translate(-50%, 190px);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  /*Появление сверху вниз*/
  @keyframes CardDown {
    0% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, 190px);
    }
  }
}
@media (max-width: 640px) {
  .progress_icon,
  .progress_icon-first {
    padding: 14px;
  }
  .progress_icon .progress_content,
  .progress_icon-first .progress_content {
    font-size: 14px;
  }
}
@media (max-width: 520px) {
  .video_content .info_block .card {
    padding: 26px;
  }
  .video_content .info_block .card .card_marker {
    margin-right: 16px;
  }
  .video_content .info_block .card .card_descr .card_descr-text {
    font-size: 18px;
    line-height: 18px;
  }
  .video_content .info_block .card .card_descr .card_descr-title {
    font-size: 28px;
    line-height: 28px;
  }
  .video_content .info_block .card {
    bottom: 200px;
  }
  /*Поднимает кнопку снизу вверх на px*/
  @keyframes CardUp {
    0% {
      opacity: 0;
      transform: translate(-50%, 200px);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  /*Появление сверху вниз*/
  @keyframes CardDown {
    0% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, 200px);
    }
  }
}
@media (max-width: 440px) {
  .video_content .info_block .card {
    padding: 20px;
  }
  .video_content .info_block .card .card_marker {
    margin-right: 14px;
  }
  .video_content .info_block .card .card_descr .card_descr-text,
  .video_content .info_block .card .card_descr .card_descr-title {
    max-width: 180px;
  }
  .video_content .info_block .card .card_descr .card_descr-text {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 7px;
  }
  .video_content .info_block .card .card_descr .card_descr-title {
    font-size: 22px;
    line-height: 22px;
  }
  .video_content .learn_button {
    padding: 20px;
  }
  .video_content .learn_button button {
    font-size: 16px;
    line-height: 16px;
  }
}
html.a-fullscreen {
  position: static !important;
}

html.a-fullscreen.position {
  position: fixed !important;
}

html.a-fullscreen body {
  overflow: auto;
}

.aframeOne, .aframeTwo,
#aframeHTMLOne, #aframeHTMLTwo {
  height: 100%;
  width: 100%;
}

a-scene {
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
  position: relative;
}

#scenePier {
  height: 100%;
  width: 100%;
}

#aframe_rollupPier,
#aframe_rollupSinich {
  display: none;
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 24px 30px;
  background: rgba(26, 27, 29, 0.7);
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
}

.aframeOne svg,
.aframeTwo svg {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card-content {
  z-index: 1;
}

.a-enter-vr-button {
  display: none !important;
}

@media (max-width: 1200px) {
  .a-dialog {
    border-radius: 20px;
    background: #A7A5B4;
    padding: 20px;
    max-height: 250px;
    height: 100%;
  }
  .a-dialog-allow-button,
  .a-dialog-deny-button,
  .a-dialog-ok-button {
    border-radius: 10px;
  }
  .a-dialog-allow-button,
  .a-dialog-deny-button,
  .a-dialog-ok-button,
  .a-dialog-text,
  .a-https-text {
    font-family: "MoscowSans", sans-serif;
    color: #ffffff;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
  .a-dialog-text {
    margin-bottom: 20px;
  }
  .a-dialog-allow-button {
    background-color: #59C598;
  }
  .a-dialog-deny-button {
    background-color: #BBB9C8;
  }
  .a-dialog-ok-button {
    background-color: #BBB9C8;
  }
}
.vectary {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vectary img.vectary_plug {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vectary iframe {
  border: none;
}

.vectary .container {
  position: absolute;
  top: unset;
  left: 0;
  right: 0;
  bottom: 20px;
  max-height: max-content;
  height: 100%;
  pointer-events: none;
}

.vectary .info_wrapper {
  position: relative;
  top: 20px;
  height: 100%;
}

.vectary .info_wrapper .info_position {
  position: absolute;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  bottom: 20px;
  width: 100%;
}

.vectary .tech_speck {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border-radius: unset;
  border: none;
  background: none;
  backdrop-filter: none;
}

.vectary .info_wrapper .info_position .speck_wrapper {
  width: 100%;
}

.vectary .tech_speck .tech_speck-click {
  display: none !important;
}

.vectary .tech_speck .dots p {
  font-family: "MoscowSans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  color: #FFF;
  margin: 0;
}

.vectary .tech_speck .dots:not(.last) {
  position: relative;
}

.vectary .tech_speck .dots:not(.last):before,
.vectary .tech_speck .dots:not(.last):after {
  content: "";
  position: absolute;
  right: -3px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.vectary .tech_speck .dots:not(.last):before {
  top: -2px;
}

.vectary .tech_speck .dots:not(.last):after {
  bottom: -2px;
}

.vectary .excursion_buttons,
.vectary .tech_speck {
  pointer-events: all;
}

.vectary .interior_button,
.vectary .ar_button {
  padding: 26px 10px;
  border: none;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  font-family: "MoscowSans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  transition: 0.7s;
  max-width: 315px;
  width: 100%;
  height: 48%;
  box-sizing: border-box;
  justify-content: center;
}

.vectary .ar_button {
  text-decoration: none;
}

.vectary .interior_button img,
.vectary .ar_button img {
  margin-right: 8px;
  transition: transform 0.7s ease;
}

.vectary .interior_button:hover,
.vectary .ar_button:hover {
  background: url("/public/images/sepia.png"), #000000;
}

.vectary .excursion_buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 30%;
  width: 100%;
}

.vectary .tech_speck .dots {
  background: linear-gradient(290deg, rgba(161, 159, 174, 0.8) 0%, rgba(172, 170, 186, 0.8) 100%);
  backdrop-filter: blur(10px);
  padding: 20px 30px;
  width: 43%;
}

.vectary .tech_speck .dots:not(.last) {
  border-right: unset;
}

.vectary .tech_speck .dots.second,
.vectary .tech_speck .dots.fourth {
  border-radius: 20px 0 0 20px;
  border-right: 2px solid #b9b8c4;
}

.vectary .tech_speck .dots.second,
.vectary .tech_speck .dots.third {
  border-bottom: 2px solid #b9b8c4;
}

.vectary .tech_speck .dots.third:before {
  content: none;
}

.vectary .tech_speck .dots.second:after {
  bottom: -3px;
  left: 10px;
}

.vectary .tech_speck .dots.fourth:before {
  top: -4px;
}

.vectary .tech_speck .dots.second:before,
.vectary .tech_speck .dots.fourth:before,
.vectary .tech_speck .dots.fourth:after {
  right: -4px;
}

.vectary .tech_speck .dots.third:after {
  bottom: -3px;
  right: 10px;
}

.vectary .tech_speck .dots.third,
.vectary .tech_speck .dots.last {
  border-radius: 5px 20px 20px 5px;
}

@media (max-width: 1024px) {
  .vectary .tech_speck .dots p {
    font-size: 16px;
    line-height: 20.8px;
  }
}
@media (max-width: 1300px) {
  .vectary .tech_speck .dots {
    width: 40%;
  }
}
@media screen and (min-width: 1200px) and (max-height: 820px) {
  /* Стили для экранов с шириной больше 1200px и высотой меньше 820px */
  .vectary .tech_speck {
    pointer-events: none;
    justify-content: flex-start;
    width: 80%;
  }
  .vectary .tech_speck .dots {
    pointer-events: all;
    width: 30%;
    padding: 20px 26px;
  }
}
@media (max-width: 930px) {
  .vectary .tech_speck .dots {
    width: 37%;
  }
}
@media (max-width: 768px) {
  .vectary .info_wrapper {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
  .vectary .info_wrapper .info_position {
    position: static;
    flex-direction: column;
    gap: 10px;
  }
  .vectary .speck_wrapper {
    order: 1;
  }
  .vectary .excursion_buttons {
    flex-direction: row;
    width: 95%;
    max-width: unset;
    justify-content: flex-end;
    gap: 10px;
  }
  .vectary .interior_button, .vectary .ar_button {
    padding: 10px 22px;
    height: 100%;
    width: 45%;
    font-size: 16px;
  }
}
@media (max-width: 540px) {
  .vectary .info_wrapper .info_position {
    transform: translateY(79%);
    transition: all 0.5s ease;
  }
  .vectary .info_wrapper .info_position.active {
    transform: translateY(0);
  }
  .vectary .tech_speck,
  .vectary .excursion_buttons {
    max-width: unset;
  }
  .vectary .excursion_buttons {
    justify-content: center;
    width: 100%;
  }
  .vectary .interior_button, .vectary .ar_button {
    font-size: 14px;
  }
  .vectary .interior_button {
    width: 100%;
    max-width: unset;
  }
  .vectary .tech_speck .dots.first {
    display: flex !important;
    padding: 15px 30px;
  }
  .vectary .tech_speck .tech_speck-click .tech_speck-img {
    transform: rotate(-180deg);
    transition: all 0.5s ease;
  }
  .vectary .tech_speck .tech_speck-click .tech_speck-img.active {
    transform: rotate(0);
  }
  .vectary .tech_speck .dots {
    max-width: unset;
    width: 100%;
  }
  .vectary .tech_speck .dots.second,
  .vectary .tech_speck .dots.fourth {
    border-right: unset;
  }
  .vectary .tech_speck .dots.first,
  .vectary .tech_speck .dots.second,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.fourth,
  .vectary .tech_speck .dots.last {
    border-radius: 10px;
  }
  .vectary .tech_speck .dots.second,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.fourth,
  .vectary .tech_speck .dots.last {
    opacity: 0;
  }
  .vectary .info_wrapper .info_position.active .tech_speck .dots.second,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.third,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.fourth,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.last {
    opacity: 1;
  }
  .vectary .tech_speck .dots.first,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.last {
    border-right: unset;
  }
  .vectary .tech_speck .dots.first,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.fourth {
    border-bottom: unset;
  }
  .vectary .info_wrapper .info_position.active .tech_speck .dots.first,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.third,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.fourth {
    border-bottom: 2px solid #b9b8c4;
  }
  .vectary .tech_speck .dots:not(.last):before,
  .vectary .tech_speck .dots:not(.last):after {
    content: "";
    opacity: 0;
  }
  .vectary .info_wrapper .info_position.active .tech_speck .dots:not(.last):before,
  .vectary .info_wrapper .info_position.active .tech_speck .dots:not(.last):after {
    opacity: 1;
  }
  .vectary .tech_speck .dots:not(.last):before {
    bottom: -3px;
    right: 10px;
    left: unset;
    top: unset;
  }
  .vectary .tech_speck .dots:not(.last):after {
    bottom: -3px;
    right: unset;
    left: 10px;
    top: unset;
  }
}
@media (max-width: 440px) {
  .vectary .interior_button, .vectary .ar_button {
    font-size: 12px;
  }
}
@media (max-width: 370px) {
  .vectary .interior_button, .vectary .ar_button {
    padding: 10px 10px;
    margin: 0;
  }
  .vectary .ar_button img {
    height: 24px;
  }
  .vectary .interior_button img {
    width: 30px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1200px) {
  .briffAR {
    position: absolute;
    z-index: 1;
    height: max-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: unset;
    background-color: #403F46;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    transition: all 0.5s;
  }
  .briffAR .briffAR_text {
    font-family: "MoscowSans", sans-serif;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin: 0;
    width: max-content;
  }
  .briffAR .closeAR {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .hideARButton {
    position: absolute;
    width: 72px;
    height: 40px;
    top: 16px;
    right: 20px;
    left: unset;
    bottom: unset;
    background-color: #9794A6;
  }
}
.BackToInnovation {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px 8px 8px;
  color: white;
  border-radius: 32px;
  border: 2px solid #5F35FF;
  background: linear-gradient(98deg, #070021 6.79%, #0F0144 85.82%);
  cursor: pointer;
  z-index: 1000;
}

.BackToInnovation .innovation_img {
  margin-right: 8px;
}

.BackToInnovation .innovation_text {
  font-family: "MoscowSans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

section {
  scroll-snap-align: start;
}

section#pier-description,
section#pier-routes {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

section#sinichka-section {
  height: calc(100vh - 40px);
}

section#pier-faq.video_active,
section#pier-routes.video_active,
section#sinichka-section.video_active,
footer#footer.video_active {
  display: none;
}

section#pier-description.fixed {
  position: fixed;
  top: 0;
  z-index: -1;
}

#pier-description .container {
  padding-bottom: 180px;
}

.section-video {
  position: fixed !important;
}

.sinichka_fixed {
  height: 100vh;
  width: 100%;
  position: sticky;
  z-index: 15;
}

@media (max-width: 1200px) {
  section#pier-description,
  section#pier-routes {
    height: auto;
    display: block;
  }
}
@media (max-height: 1000px) {
  section#pier-description,
  section#pier-routes {
    height: max-content;
  }
}
@media (min-height: 830px) and (max-width: 680px) {
  section#sinichka-section {
    height: 100vh;
  }
}
.google_viewer {
  display: none;
}

.popup_design {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  max-height: 100vh;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: auto;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.popup_design.active {
  pointer-events: all;
  z-index: 99999;
}

.popup_design p {
  margin: 0;
}

.popup_design.active .popup_wrapper {
  position: relative;
  padding: 20px;
  max-width: 240px;
  background-color: #fcfcfc;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 14px;
}

.popup_design.arSupport-popup.active .popup_wrapper {
  max-width: 350px;
}

.popup_design .title {
  font-family: "MoscowSans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #252525;
}

.popup_design.arSupport-popup .title {
  text-align: unset;
  width: 100%;
}

.qrCode-popup .qr_code {
  max-width: 184px;
  width: 100%;
}

.popup_design .description {
  font-family: "MoscowSans", sans-serif;
  font-size: 13px;
  line-height: 15px;
  font-weight: 100;
  text-align: center;
  color: #6d6d6d;
}

.popup_design.arSupport-popup .description {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  text-align: unset;
}

.popup_design .closeButton {
  text-align: center;
  width: 100%;
  color: #252525;
  font-family: "MoscowSans", sans-serif;
  font-size: 13px;
  padding: 5px;
  background-color: #efefef;
  cursor: pointer;
  border: 1px solid #efefef;
  border-radius: 5px;
  transition: background-color 0.5s;
}

.popup_design .closeButton:hover {
  background-color: #fcfcfc;
}

.pulse_wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  top: 20px;
  z-index: 1;
  background-color: black;
  width: 68px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid white;
  cursor: pointer;
}

.pulse_wrapper.hidden .pulse {
  display: none;
  animation: none;
}

.pulse_media {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.pulse_media.loading .pulse_img:first-child {
  display: none;
}

.pulse_media svg {
  display: none;
}

.pulse_media.loading svg {
  display: block;
}

.pulse {
  position: absolute;
  text-align: center;
  border-radius: 8px;
  background-color: white;
  width: 88px;
  height: 42px;
  animation: move 1.5s linear infinite;
}

@keyframes move {
  50%, 100% {
    opacity: 0;
    transform: translate(200px, 0);
  }
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  20% {
    opacity: 0.5;
  }
  40% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@media (max-width: 480px) {
  .popup_design.arSupport-popup {
    padding: 0;
  }
  .popup_design.arSupport-popup .popup_wrapper {
    position: absolute;
    bottom: -30%;
    transition: all 0.6s ease;
  }
  .popup_design.arSupport-popup.active .popup_wrapper {
    max-width: unset;
    position: absolute;
    bottom: 0;
    border-radius: 5px 5px 0 0;
  }
  .popup_design .title {
    font-size: 16px;
  }
  .popup_design.arSupport-popup .description {
    font-size: 12px;
    line-height: 16px;
  }
}

