.google_viewer {
    display: none;
}

.popup_design {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    // z-index: -99999;
    z-index: 99999;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: auto;
    padding: 0 20px;
    background: rgba(255,255,255,0.10);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
}

.popup_design.active {
    pointer-events: all;
    z-index: 99999;
}

.popup_design p {
    margin: 0;
}

.popup_design.active .popup_wrapper {
    position: relative;
    padding: 20px;
    max-width: 240px;
    background-color: #fcfcfc;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 14px;
}

.popup_design.arSupport-popup.active .popup_wrapper {
    max-width: 350px;
}

.popup_design .title {
    font-family: 'MoscowSans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #252525;
}

.popup_design.arSupport-popup .title {
	text-align: unset;
    width: 100%;
}

.qrCode-popup .qr_code {
    max-width: 184px;
    width: 100%;
}

.popup_design .description {
    font-family: 'MoscowSans', sans-serif;
    font-size: 13px;
    line-height: 15px;
    font-weight: 100;
    text-align: center;
    color: #6d6d6d;
}

.popup_design.arSupport-popup .description {
	font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    text-align: unset;
}

.popup_design .closeButton {
    text-align: center;
    width: 100%;
    color: #252525;
    font-family: 'MoscowSans', sans-serif;
    font-size: 13px;
    padding: 5px;
    background-color: #efefef;
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 5px;
    transition: background-color .5s;
}

.popup_design .closeButton:hover {
    background-color: #fcfcfc;
}

.pulse_wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 20px;
    top: 20px;
    z-index: 1;
    background-color: black;
    width: 68px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid white;
    cursor: pointer;
}

.pulse_wrapper.hidden .pulse {
    display: none;
    animation: none;
}

.pulse_media {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.pulse_media.loading .pulse_img:first-child {
    display: none;
}

.pulse_media svg {
    display: none;
}

.pulse_media.loading svg {
    display: block;
}

.pulse {
    position: absolute;
    text-align: center;
    border-radius: 8px;
    background-color: white;
    width: 88px;
    height: 42px;
    animation: move 1.5s linear infinite;
}

@keyframes move {
    50%, 100% {
        opacity: 0;
        transform: translate(200px, 0);
    }
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    20% {
        opacity: 0.5;
    }
    40% {
        transform: scale(1.2);
        opacity: 0;
    }
}

@media (max-width: 480px) {
    .popup_design.arSupport-popup {
        padding: 0;
    }

    .popup_design.arSupport-popup .popup_wrapper {
        position: absolute;
        bottom: -30%;
        transition: all .6s ease;
    }
    
    .popup_design.arSupport-popup.active .popup_wrapper {
        max-width: unset;
        position: absolute;
        bottom: 0;
        border-radius: 5px 5px 0 0;
    }

    .popup_design .title {
        font-size: 16px;
    }

    .popup_design.arSupport-popup .description {
        font-size: 12px;
        line-height: 16px;
    }
}