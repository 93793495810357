.BackToInnovation {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px 8px 8px;
  color: white;
  border-radius: 32px;
  border: 2px solid #5F35FF;
  background: linear-gradient(98deg, #070021 6.79%, #0F0144 85.82%);
  cursor: pointer;
  z-index: 1000;
}

.BackToInnovation .innovation_img {
  margin-right: 8px;
}

.BackToInnovation .innovation_text {
  font-family: 'MoscowSans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

section {
  scroll-snap-align: start;
}

section#pier-description,
section#pier-routes {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

section#sinichka-section {
  height: calc(100vh - 40px);
}

section#pier-faq.video_active,
section#pier-routes.video_active,
section#sinichka-section.video_active,
footer#footer.video_active {
  display: none;
}

section#pier-description.fixed {
  position: fixed;
  top: 0;
  z-index: -1;
}


#pier-description .container {
  padding-bottom: 180px;
}

.section-video {
  position: fixed !important;
}

.sinichka_fixed {
  height: 100vh;
  width: 100%;
  position: sticky;
  z-index: 15;
}

@media (max-width: 1200px) {
  section#pier-description,
  section#pier-routes {
    height: auto;
    display: block;
  }
}

@media (max-height: 1000px) {
  section#pier-description,
  section#pier-routes {
    height: max-content;
  }
}

@media (min-height: 830px) and (max-width: 680px) {
  section#sinichka-section {
    height: 100vh;
  }
}


