.container_video {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  //max-width: 1340px;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 2;
}

.video_progress_container {
  //position: fixed;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_progress_container .progress-container {
  position: relative;
  max-width: 374px;
  width: 100%;
  height: 5px;
  border-radius: 2px;
  //opacity: 0.5;
  background: #9794A6;
  transition: background-color .4s; /* Добавляем переход для background-color */
  margin: 0 8px;
}

//.video_progress_container .progress-container.last {
//  //margin-left: 8px;
//  //margin-right: 20px;
//  //max-width: 316px;
//  //max-width: 270px;
//}

.progress-bar {
  height: 100%;
  width: 0;
  border-radius: 2px;
  background: #FFF;
  transition: width .4s;
}

//.progress_icon,
//.progress_icon-first {
//  position: relative;
//  max-width: 40px;
//  width: 100%;
//  height: 40px;
//  z-index: 1;
//  border-radius: 100%;
//  //opacity: 0.5;
//  background: #9794A6;
//}

.progress_icon,
.progress_icon-first {
  position: relative;
  //max-width: 50px;
  //width: 100%;
  //height: 50px;
  padding: 30px;
  z-index: 1;
  border-radius: 10px;
  //opacity: 0.5;
  background: transparent;
  border: 2px solid #9794A6;
}

.progress_icon-first {
  margin-left: 20px;
}

.progress_icon .progress_content,
.progress_icon-first .progress_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: "MoscowSans", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #9794A6;
  transition: all .5s ease;
}

//.progress_icon.last {
//  display: none;
//}

//.progress_icon-first,
//.progress_icon.filled {
//    background-color: #FFF;
//    opacity: 1;
//}

.progress_icon-first,
.progress_icon.filled {
  background-color: #FFF;
  border-color: #FFF;
  opacity: 1;
}

.progress_icon-first .progress_content,
.progress_icon.filled .progress_content {
  color: #050404;
}

.progress_icon:last-child {
  margin-right: 20px;
}




//.video_content {
//  position: relative;
//  display: block;
//}

//.site_content {
//  display: none;
//}

.video_content {
  //height: 2000vh;
  //opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 16;
  //overflow: hidden; /* Скрыть видео, которые не воспроизводятся */
  transition: opacity .5s;
  background: url("/public/images/firstCadr.jpg") no-repeat center/cover;
}

.video_content .video-background,
.video_content .video-background-wrapper {
  display: none;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  //position: fixed;
  //bottom: 0;
  //transform: scaleX(1.04);
  //position: fixed;
  //top: 50%;
  //left: 50%;
  //min-width: 100%;
  //min-height: 100%;
  //transform: translate(-50%, -50%);
}

.video_content .video-background-wrapper {
  height: 100%;
}

// Колесо прокрутеи

//.video_content .mouse_prev .mouse_prev-wrapper_desctop {
//  position: absolute;
//  left: 50%;
//  transform: translateX(-50%);
//  bottom: 74px;
//  width: 25px;
//  height: 40px;
//  //background-color: black;
//  opacity: 0.8;
//  background: #9794A6;
//  border: 2px solid white;
//  border-radius: 20px;
//}

.video_content .mouse_prev .mouse_prev-wrapper_mobile,
.video_content .mouse_prev .mouse_prev-wrapper_desctop {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150px;
  color: #9794A6;
  font-family: "MoscowSans", sans-serif;
  font-weight: 500;
}

.video_content .mouse_prev .mouse_prev-wrapper_desctop {
  display: none;
  font-size: 22px;
}

.video_content .mouse_prev .mouse_prev-wrapper_mobile {
  display: none;
  font-size: 16px;
}

.video_content .mouse_prev .mouse_prev-wrapper_desctop .mouse_prev_arrow {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 42px;
  opacity: 0.5;
}

.video_content .mouse_prev .mouse_prev-wrapper_desctop .mouse_prev_arrow img {
  position: absolute;
  height: 32px;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  animation: mouse_arrow 1.1s linear infinite;
}
//
//.video_content .mouse_prev .mouse_prev-wrapper_desctop .mouse_prev_dot {
//  position: absolute;
//  bottom: 10px;
//  left: 50%;
//  transform: translateX(-50%);
//  width: 2px;
//  height: 2px;
//  border-radius: 100%;
//  background-color: #FFFFFF;
//}

@keyframes mouse_arrow {
  0% {
    top: 5px;
  }
  50% {
    top: 10px;
  }
  100% {
    top: 5px;
  }
}


// Код для проблеска света

.autoflash{
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.flash{
  content: "";
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 3em;
  display: block;
  position: absolute;
  top: 0;
  left: -4.5em;
  -webkit-transform: skewX(-45deg) translateX(0);
  transform: skewX(-45deg) translateX(0);
  -webkit-transition: none;
  transition: all .4s;
}

.lighting {
  -webkit-animation: moving 5s ease-in-out infinite;
  -moz-animation: moving 5s ease-in-out infinite;
  -ms-animation: moving 5s ease-in-out infinite;
  -o-animation: moving 5s ease-in-out infinite;
  animation: moving 5s ease-in-out infinite;

}

@keyframes moving {
  30% {  webkit-transform: skewX(-45deg) translateX(33.5em);
    transform: skewX(-45deg) translateX(33.5em);
  }

  100% {  webkit-transform: skewX(-45deg) translateX(33.5em);
    transform: skewX(-45deg) translateX(33.5em);

  }
}


// Кнопка причалить, начало и узнать больше

.video_content .leave_button {
  display: none;
  //right: 20px;
  right: 20px;
}

.video_content .reload_button {
  display: none;
  left: 20px;
}

.video_content .leave_button,
.video_content .reload_button {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 82px;
  cursor: pointer;
  z-index: 1;
}

.video_content .leave_button button,
.video_content .reload_button button {
  font-family: "MoscowSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  margin: 0;
  padding: 0;
  color: white;
  background: transparent;
  cursor: pointer;
}

.video_content .reload_button img {
  transform: rotate(180deg);
}

.video_content .learn_button {
  display: none;
  position: absolute;
  //bottom: 32px;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 28px;
  width: max-content;
  cursor: pointer;
  z-index: 1;
  border-radius: 20px;
  background: linear-gradient(292deg, rgba(161, 159, 174, 0.50) 0%, rgba(172, 170, 186, 0.50) 100%);
  backdrop-filter: blur(10px);
}

.video_content .learn_button button {
  text-transform: uppercase;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: "MoscowSans", sans-serif;
  font-size: 22px;
  line-height: 20px;
  font-weight: 300;
  color: #ffffff;
  background: transparent;
}


// Карточки с информацией

.video_content .info_block,
.video_content .mouse_prev {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.video_content .info_block .card {
  position: absolute;
  max-width: 474px;
  width: max-content;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  padding: 32px;
  border-radius: 20px;
  background: linear-gradient(292deg, rgba(161, 159, 174, 0.50) 0%, rgba(172, 170, 186, 0.50) 100%);
  backdrop-filter: blur(10px);
  //bottom: 144px;
  //bottom: 220px;
  bottom: 260px;
  left: 50%;
  transform: translateX(-50%);
}

.video_content .info_block .card .card_marker {
  position: relative;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.40);
}

.video_content .info_block .card .card_marker .card_marker-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video_content .info_block .card .card_descr .card_descr-text,
.video_content .info_block .card .card_descr .card_descr-title {
  font-family: "MoscowSans", sans-serif;
  margin: 0;
}

.video_content .info_block .card .card_descr .card_descr-title {
  font-size: 32px;
  line-height: 35px;
  font-weight: 600;
  color: #ffffff;
}

.video_content .info_block .card .card_descr .card_descr-text {
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  color: #CBCBCD;
  margin-bottom: 10px;
}


.video_content .info_block .card.animateUp {
  animation: CardUp .5s linear;
}

.video_content .info_block .card.animateDown {
  animation: CardDown .5s linear;
}

// Анимации появления и скрытия блоков info_block

/*Поднимает кнопку снизу вверх на px*/
@keyframes CardUp {
  0% {
    opacity: 0;
    //transform: translate(-50%, 144px);
    //transform: translate(-50%, 220px);
    transform: translate(-50%, 260px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/*Появление сверху вниз*/
@keyframes CardDown {
  0% {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 1;
    //transform: translate(-50%, 144px);
    //transform: translate(-50%, 220px);
    transform: translate(-50%, 260px);
  }
}

//Видео блок в контенте
.section-video {
  pointer-events: none;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  height: 100vh;
  object-fit: cover;
}

.section-video.section-video_active {
  display: block !important;
  z-index: 100 !important;
}


//@media (max-width: 1920px) {
//  .video_content .reload_button {
//    left: 20px;
//  }
//}
//
//@media (max-width: 1670px) {
//  .video_content .reload_button {
//    top: 70px;
//    bottom: unset;
//  }
//}

@media (min-height: 850px) and (max-width: 680px) {
  .video_content .video-background-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 250px;
    z-index: 1;
    background: linear-gradient(180deg, #000 80%, rgba(34, 34, 34, 0.00) 100%);
  }

  .video_content .video-background,
  .video_content .video-background-wrapper {
    position: fixed;
    bottom: 0;
    transform: scaleX(1.04);
  }
}

@media (max-width: 1200px) {
  .video_content .bg-video,
  .video_progress_container,
  .video_content .mouse_prev .mouse_prev-wrapper_desctop,
  .video_content .mouse_prev .mouse_prev-wrapper_mobile,
  .video_content .leave_button, .video_content .reload_button {
    display: none;
  }

  .video_content .video-background,
  .video_content .video-background-wrapper {
    display: block;
  }

  .video_content {
    background: black;
  }

  .video_content .learn_button {
    display: flex;
  }

  .video_content .info_block .card {
    bottom: 190px;
  }

  /*Поднимает кнопку снизу вверх на px*/
  @keyframes CardUp {
    0% {
      opacity: 0;
      transform: translate(-50%, 190px);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  /*Появление сверху вниз*/
  @keyframes CardDown {
    0% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, 190px);
    }
  }

  //.video_content .mouse_prev .mouse_prev-wrapper_mobile {
  //  display: block;
  //}
}

//@media only screen and (min-width: 700px) and (max-width: 1200px) and (orientation: landscape) {
//  .video_content:before {
//    content: '';
//    position: absolute;
//    top: 0;
//    left: 0;
//    right: 0;
//    width: 100%;
//    height: 130px;
//    z-index: 1;
//    background: linear-gradient(180deg, #000 80%, rgba(34, 34, 34, 0.00) 100%);
//  }
//
//  .video_content .video-background, .video_content .video-background-wrapper {
//    transform: scale(1.065);
//    position: absolute;
//    bottom: 0;
//    height: unset;
//  }
//}

@media (max-width: 640px) {
  .progress_icon,
  .progress_icon-first {
    padding: 14px;
  }

  .progress_icon .progress_content,
  .progress_icon-first .progress_content {
    font-size: 14px;
  }
}

@media (max-width: 520px) {
  .video_content .info_block .card {
    padding: 26px;
  }

  .video_content .info_block .card .card_marker {
    margin-right: 16px;
  }

  .video_content .info_block .card .card_descr .card_descr-text {
    font-size: 18px;
    line-height: 18px;
  }

  .video_content .info_block .card .card_descr .card_descr-title {
    font-size: 28px;
    line-height: 28px;
  }

  .video_content .info_block .card {
    bottom: 200px;
  }

  /*Поднимает кнопку снизу вверх на px*/
  @keyframes CardUp {
    0% {
      opacity: 0;
      transform: translate(-50%, 200px);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  /*Появление сверху вниз*/
  @keyframes CardDown {
    0% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, 200px);
    }
  }
}

@media (max-width: 440px) {
  //.video_progress_container,
  //.video_content .mouse_prev .mouse_prev-wrapper_mobile {
  //  display: none;
  //}

  //.progress_icon-first {
  //  margin-left: 10px;
  //}
  //
  //.progress_icon:last-child {
  //  margin-right: 10px;
  //}
  //
  //.video_progress_container .progress-container {
  //  margin: 0 4px;
  //}

  .video_content .info_block .card {
    padding: 20px;
  }

  .video_content .info_block .card .card_marker {
    margin-right: 14px;
  }

  .video_content .info_block .card .card_descr .card_descr-text,
  .video_content .info_block .card .card_descr .card_descr-title {
    max-width: 180px;
  }

  .video_content .info_block .card .card_descr .card_descr-text {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 7px;
  }

  .video_content .info_block .card .card_descr .card_descr-title {
    font-size: 22px;
    line-height: 22px;
  }

  .video_content .learn_button {
    padding: 20px;
  }

  .video_content .learn_button button {
    font-size: 16px;
    line-height: 16px;
  }
}