.vectary {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vectary img.vectary_plug {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vectary iframe {
  border: none;
}

.vectary .container {
  position: absolute;
  top: unset;
  left: 0;
  right: 0;
  bottom: 20px;
  max-height: max-content;
  height: 100%;
  pointer-events: none;
}

.vectary .info_wrapper {
  position: relative;
  top: 20px;
  height: 100%;
}

.vectary .info_wrapper .info_position {
  position: absolute;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  bottom: 20px;
  width: 100%;
}

.vectary .tech_speck {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border-radius: unset;
  border: none;
  background: none;
  backdrop-filter: none;
}

.vectary .info_wrapper .info_position .speck_wrapper {
  width: 100%;
}

.vectary .tech_speck .tech_speck-click {
  display: none !important;
}

.vectary .tech_speck .dots p {
  font-family: "MoscowSans", sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  color: #FFF;
  margin: 0;
}

.vectary .tech_speck .dots:not(.last) {
  position: relative;
}

.vectary .tech_speck .dots:not(.last):before,
.vectary .tech_speck .dots:not(.last):after {
  content: '';
  position: absolute;
  right: -3px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.vectary .tech_speck .dots:not(.last):before {
  top: -2px;
}

.vectary .tech_speck .dots:not(.last):after {
  bottom: -2px;
}

.vectary .excursion_buttons,
.vectary .tech_speck {
  pointer-events: all;
}

.vectary .interior_button,
.vectary .ar_button {
  padding: 26px 10px;
  border: none;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.70);
  display: flex;
  align-items: center;
  font-family: "MoscowSans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 500;
  color: #FFF;
  cursor: pointer;
  transition: 0.7s;

  max-width: 315px;
  width: 100%;
  height: 48%;
  box-sizing: border-box;
  justify-content: center;
}

.vectary .ar_button {
  text-decoration: none;
}

.vectary .interior_button img,
.vectary .ar_button img {
  margin-right: 8px;
  transition: transform 0.7s ease;
}

.vectary .interior_button:hover,
.vectary .ar_button:hover {
  background: url('/public/images/sepia.png'), #000000;
}

.vectary .excursion_buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 30%;
  width: 100%;
}

.vectary .tech_speck .dots {
  background: linear-gradient(290deg, rgba(161, 159, 174, 0.80) 0%, rgba(172, 170, 186, 0.80) 100%);
  backdrop-filter: blur(10px);
  padding: 20px 30px;
  width: 43%;
}

.vectary .tech_speck .dots:not(.last) {
  border-right: unset;
}

.vectary .tech_speck .dots.second,
.vectary .tech_speck .dots.fourth {
  border-radius: 20px 0 0 20px;
  border-right: 2px solid #b9b8c4;
}

.vectary .tech_speck .dots.second,
.vectary .tech_speck .dots.third {
  border-bottom: 2px solid #b9b8c4;
}

.vectary .tech_speck .dots.third:before {
  content: none;
}

.vectary .tech_speck .dots.second:after {
  bottom: -3px;
  left: 10px;
}

.vectary .tech_speck .dots.fourth:before {
  top: -4px;
}

.vectary .tech_speck .dots.second:before,
.vectary .tech_speck .dots.fourth:before,
.vectary .tech_speck .dots.fourth:after {
  right: -4px;
}

.vectary .tech_speck .dots.third:after {
  bottom: -3px;
  right: 10px;
}

.vectary .tech_speck .dots.third,
.vectary .tech_speck .dots.last {
  border-radius: 5px 20px 20px 5px;
}

@media (max-width: 1024px) {
  .vectary .tech_speck .dots p {
    font-size: 16px;
    line-height: 20.8px;
  }
}

@media (max-width: 1300px) {
  .vectary .tech_speck .dots {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) and (max-height: 820px) {
	/* Стили для экранов с шириной больше 1200px и высотой меньше 820px */
	.vectary .tech_speck {
    pointer-events: none;
		justify-content: flex-start;
		width: 80%;
	}

	.vectary .tech_speck .dots {
    pointer-events: all;
		width: 30%;
		padding: 20px 26px;
	}
}

@media (max-width: 930px) {
  .vectary .tech_speck .dots {
    width: 37%;
  }
}

@media (max-width: 768px) {
  .vectary .info_wrapper {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .vectary .info_wrapper .info_position {
    position: static;
    flex-direction: column;
    gap: 10px;
  }

  .vectary .speck_wrapper {
    order: 1;
  }

  .vectary .excursion_buttons {
    flex-direction: row;
    width: 95%;
    max-width: unset;
    justify-content: flex-end;
    gap: 10px;
  }

  .vectary .interior_button, .vectary .ar_button {
    padding: 10px 22px;
    height: 100%;
    width: 45%;
    font-size: 16px;
  }
}

@media (max-width: 540px) {
  .vectary .info_wrapper .info_position {
    // transform: translateY(78%);
    // transform: translateY(79.62%);
    transform: translateY(79%);
    transition: all .5s ease;
  }

  .vectary .info_wrapper .info_position.active {
    transform: translateY(0);
  }

  .vectary .tech_speck,
  .vectary .excursion_buttons {
    max-width: unset;
  }

  .vectary .excursion_buttons {
    justify-content: center;
    width: 100%;
  }

  .vectary .interior_button, .vectary .ar_button {
    font-size: 14px;
  }

  .vectary .interior_button {
    width: 100%;
    max-width: unset;
  }

  // .vectary .tech_speck {
  //   transition: all .5s ease;
  // }

  .vectary .tech_speck .dots.first {
    display: flex !important;
    padding: 15px 30px;
  }

  .vectary .tech_speck .tech_speck-click .tech_speck-img {
    transform: rotate(-180deg);
    transition: all .5s ease;
  }

  .vectary .tech_speck .tech_speck-click .tech_speck-img.active {
    transform: rotate(0);
  }

  .vectary .tech_speck .dots {
    max-width: unset;
    width: 100%;
  }

  .vectary .tech_speck .dots.second,
  .vectary .tech_speck .dots.fourth {
    border-right: unset;
  }

  .vectary .tech_speck .dots.first,
  .vectary .tech_speck .dots.second,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.fourth,
  .vectary .tech_speck .dots.last {
    // border-radius: 20px;
    border-radius: 10px;
  }

  .vectary .tech_speck .dots.second,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.fourth,
  .vectary .tech_speck .dots.last {
    opacity: 0;
  }

  // .vectary .info_wrapper .info_position.active .tech_speck .dots.first,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.second,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.third,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.fourth,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.last {
    opacity: 1;
  }

  .vectary .tech_speck .dots.first,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.last {
    border-right: unset;
  }

  .vectary .tech_speck .dots.first,
  .vectary .tech_speck .dots.third,
  .vectary .tech_speck .dots.fourth {
    border-bottom: unset;
  }

  .vectary .info_wrapper .info_position.active .tech_speck .dots.first,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.third,
  .vectary .info_wrapper .info_position.active .tech_speck .dots.fourth {
    border-bottom: 2px solid #b9b8c4;
  }

  .vectary .tech_speck .dots:not(.last):before,
  .vectary .tech_speck .dots:not(.last):after {
    content: '';
    opacity: 0;
  }

  .vectary .info_wrapper .info_position.active .tech_speck .dots:not(.last):before,
  .vectary .info_wrapper .info_position.active .tech_speck .dots:not(.last):after {
    opacity: 1;
  }

  .vectary .tech_speck .dots:not(.last):before {
    bottom: -3px;
    right: 10px;
    left: unset;
    top: unset;
  }

  .vectary .tech_speck .dots:not(.last):after {
    bottom: -3px;
    right: unset;
    left: 10px;
    top: unset;
  }
}

@media (max-width: 440px) {
  .vectary .interior_button, .vectary .ar_button {
    font-size: 12px;
  }
}

@media (max-width: 370px) {
  .vectary .interior_button, .vectary .ar_button {
    padding: 10px 10px;
    margin: 0;
  }

  .vectary .ar_button img {
    height: 24px;
  }

  .vectary .interior_button img {
    width: 30px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1200px) {
  .briffAR {
    position: absolute;
    z-index: 1;
    height: max-content;
    top: 0;
    left: 0;
    right: 0;
    bottom: unset;
    background-color: #403F46;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    transition: all .5s;
  }

  .briffAR .briffAR_text {
    font-family: "MoscowSans", sans-serif;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin: 0;
    width: max-content;
  }

  .briffAR .closeAR {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .hideARButton {
    position: absolute;
    width: 72px;
    height: 40px;
    top: 16px;
    right: 20px;
    left: unset;
    bottom: unset;
    background-color: #9794A6;
  }
}