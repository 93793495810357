body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // TODO: скролл для видео норм, но обрезает контент
  // overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#main {
  position: relative;
}

.bg-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  display: block;
  position: fixed;
  z-index: 0;
}

.bg-video.active {
  z-index: 1;
}

.info-text {
  position: absolute;
  background: gray;
  color: black;
  opacity: 0;
  pointer-events: none;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 500ms;
}

.info-text.active {
  opacity: 1;
  pointer-events: all;
}
